import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Message from '../../enb-styled/Message.js';
import TableListEmployees from '../../markdowns/sections/docs/employees/response-list-employees.md';
import TableRetrieveEmployee from '../../markdowns/sections/docs/employees/response-retrieve-employee.md';
import TableCreateEmployee from '../../markdowns/sections/docs/employees/request-create-employee.md';
import TableUpdateEmployee from '../../markdowns/sections/docs/employees/request-update-employee.md';
import TableTransferEmployee from '../../markdowns/sections/docs/employees/request-transfer-employee.md';
import TableTerminateEmployee from '../../markdowns/sections/docs/employees/request-terminate-employee.md';
export const _frontmatter = {
  "title": "Employees"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Employees`}</h1>
    <h2>{`Definition`}</h2>
    <p>{`An Employee is someone who is working for your organisation.`}</p>
    <p>{`Employees are classified as one of the following:`}</p>
    <ul>
      <li parentName="ul">{`Casual`}</li>
      <li parentName="ul">{`Part time (permanent or fixed/maximum term)`}</li>
      <li parentName="ul">{`Full time (permanent or fixed/maximum term)`}</li>
      <li parentName="ul">{`Labour hire`}</li>
      <li parentName="ul">{`Temporary`}</li>
      <li parentName="ul">{`Super Income Stream`}</li>
    </ul>
    <p>{`Employees can be created through two different processes:`}</p>
    <ol>
      <li parentName="ol">{`When a Candidate goes through the interview, shortlist and onboarding process it then converts, at your request, into an Employee. During this conversion, all the Candidate’s details, documents, notes, etc will be pushed to the Employee, for reference.`}</li>
      <li parentName="ol">{`From scratch, without going through the Candidate process first.`}</li>
    </ol>
    <p>{`Once an Employee is created, the following information can be captured (not limited to):`}</p>
    <ul>
      <li parentName="ul">{`Personal details,`}</li>
      <li parentName="ul">{`Payroll details,`}</li>
      <li parentName="ul">{`Training and qualifications,`}</li>
      <li parentName="ul">{`Notes (e.g. Electronic policy acknowledgements, Emails, logged interactions),`}</li>
      <li parentName="ul">{`Personal and strategic objectives,`}</li>
      <li parentName="ul">{`Performance Reviews,`}</li>
      <li parentName="ul">{`HR personnel documents.`}</li>
    </ul>
    <p>{`At the present moment, the enableHR API exposes the personal details,  payroll details (including leave balances) and  training and qualifications of Employees.
Employees have access to the enableHR self-service portal where they can review policies, action HR tasks, complete performance reviews, update personal details, update training and qualifications, create WHS incidents/hazards.`}</p>
    <p>{`enableHR API current exposure for Employees:`}</p>
    <ul>
      <li parentName="ul">{`Actions:`}
        <ul parentName="li">
          <li parentName="ul">{`List existing Employees`}</li>
          <li parentName="ul">{`Create, retrieve and update an Employee`}</li>
          <li parentName="ul">{`Transfer an Employee to another branch`}</li>
          <li parentName="ul">{`Terminate an Employee`}</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`Fields:`}
        <ul parentName="li">
          <li parentName="ul">{`Personal information (excluding custom fields)`}</li>
          <li parentName="ul">{`Bank accounts (Australia and New Zealand)`}</li>
          <li parentName="ul">{`Tax details (Australia and New Zealand)`}</li>
          <li parentName="ul">{`Super Annuation (Kiwi Saver in New Zealand)`}</li>
          <li parentName="ul">{`Leave balances`}</li>
          <li parentName="ul">{`Training and Qualification (competencies)`}</li>
        </ul>
      </li>
    </ul>
    <div id="list-all-employees">
      <h2>{`List all Employees`}</h2>
      <p>{`Use this action to list the employees visible to the user executing the API call.`}</p>
      <Message mdxType="Message">
Employees are organised in Branches, have a status (current, terminated, etc) and an employment type (permanent, casual, part time, full time, etc).
        <p>{`The list action allows you to  access this information without having to query each Employee.`}</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/employees/list-employees.png" alt="List Employees" style={{
          "maxWidth": "50%"
        }} />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/ListEmployees/"
                }}>{`GET: List Employees`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Response`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableListEmployees mdxType="TableListEmployees" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/employees/enablehr-employees.png" alt="enableHR Employees" />
      </div>
    </div>
    <h2>{`Retrieve an Employee`}</h2>
    <p>{`Use this action to retrieve the details of an Employee.`}</p>
    <Message mdxType="Message">
Employees are organised in Branches, have a status (current, terminated, etc) and an employment type (permanent, casual, part time, full time, etc).
      <p>{`The “List Employees” action allows you to have access to this information without having to query each employee.
To retrieve all the other fields,  use the Retrieve an Employee action.`}</p>
    </Message>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/retrieve-employee.png" alt="Retrieve Employee" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/RetrieveEmployee/"
              }}>{`GET: Retrieve an Employees`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableRetrieveEmployee mdxType="TableRetrieveEmployee" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/enablehr-employee-details-1.png" alt="enableHR Employee Details" style={{
        "maxWidth": "70%"
      }} />
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/enablehr-employee-details-2.png" alt="enableHR Employee Details" style={{
        "maxWidth": "70%"
      }} />
    </div>
    <h2>{`Create an Employee`}</h2>
    <p>{`Use this action to create a new Employee.`}</p>
    <Message mdxType="Message">
The only mandatory fields to create a new Employee are Branch ID, First Name and Last Name.
    </Message>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/create-employee.png" alt="Create Employee" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/CreateEmployee/"
              }}>{`POST: Create an Employees`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableCreateEmployee mdxType="TableCreateEmployee" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/enablehr-create-employee-1.png" alt="enableHR Create Employee" style={{
        "maxWidth": "90%"
      }} />
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/enablehr-create-employee-2.png" alt="enableHR Create Employee" style={{
        "maxWidth": "70%"
      }} />
    </div>
    <h2>{`Update an Employee`}</h2>
    <p>{`Use this action to update an Employee.`}</p>
    <Message mdxType="Message">
Most fields of an Employee can be updated using this action, with the exception of:
      <ul>
        <li>Employee status change as 'Transferred' to move the employee from one branch to another.</li>
        <li>Employee status change as 'Terminated'.</li>
      </ul>
    </Message>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/update-employee.png" alt="Update Employee" />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/UpdateEmployee/"
              }}>{`POST: Update an Employees`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}<br /><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#list-branches"
              }}>{`Branch ID`}</a>{` - Unique identifier of the Branch`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableUpdateEmployee mdxType="TableUpdateEmployee" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/enablehr-employee-details-3.png" alt="enableHR Employee Details" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/enablehr-employee-details-4.png" alt="enableHR Employee Details" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Transfer an Employee`}</h2>
    <p>{`Use this action to transfer an Employee from one Branch to another Branch.`}</p>
    <Message mdxType="Message">
This is the only way to transfer an Employee to another branch, as the branch cannot be updated using the Update Employee action.
      <p>{`This transfer function is used to capture when a worker has moved a department, location or cost code (depending on the branch setup structure ). Once the transfer is complete, a note is created in the Employee’s file confirming the transfer of the employee record.`}</p>
    </Message>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/transfer-employee.png" alt="Transfer Employee" />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/TransferEmployee/"
              }}>{`POST: Transfer an Employees`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}<br /><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#list-branches"
              }}>{`Branch ID`}</a>{` - Unique identifier of the (destination) Branch`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableTransferEmployee mdxType="TableTransferEmployee" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/enablehr-transfer-employee-note.png" alt="enableHR Employee Transfer Note" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <h2>{`Terminate an Employee`}</h2>
    <p>{`Use this action to terminate an Employee.`}</p>
    <Message mdxType="Message">
This is the only way to terminate an Employee.
      <p>{`If the termination date is in the future, the Status of the Employee will be temporarily set to “Terminating” and then to “Terminated” on the date.`}</p>
    </Message>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/terminate-employee.png" alt="Terminate Employee" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/TerminateEmployee/"
              }}>{`POST: Terminate an Employees`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}<br /></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableTerminateEmployee mdxType="TableTerminateEmployee" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/employees/enablehr-terminate-employee-status.png" alt="enableHR Employee Termination Status" style={{
        "maxWidth": "60%"
      }} />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      